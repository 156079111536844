/*------------------------------------
	File for your custom SCSS style
------------------------------------*/
.navbar-vertical-aside-mini-mode .navbar-brand-wrapper{
	height: 80px !important;
}

.navbar-brand-wrapper{
	height: 150px !important;
}

#left-nav .navbar-nav .nav-link {
	border: 0 !important;
	color: #96a2c1 !important;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
.header-row {
	margin-bottom: 0.5rem; 
}
.right-nav-active {
	margin-right: 83px;
}

.custom-checkbox .custom-control-label::before {
		border: 0.0625rem solid #377dff;
}

.custom-checkbox.disabled .custom-control-label::before {
		border:  0.0625rem solid #d6dbeb;
}

.checkbox-list .custom-control-label {
	width: 100% !important;
}

.checkbox-list .custom-control-label::before {
	right: 0 !important;
	left: unset;
}

.checkbox-list .custom-control-label::after {
	right: 0 !important;
	left: unset;
}

.checkbox-list .custom-control {
	padding: 0;
}

.tooltip {
	opacity: 100;
}

// Right Nav

#splittedContentMini {
	background-color: #377dff;
	position: fixed;
	right:0
}
#splittedContentMini .right-nav-icon {
	color: white;
}
#splittedContentMini .badge {
	border: 1px solid #377dff;
	margin-left: -20px !important;
	margin-top: -16px; color:white;
	background-color: #e91e63
}

.vertical-center-col-text td {
	vertical-align: middle !important
}

/* TRANSITION */
/* always present */
.expand-enter-active, .expand-leave-active {
	transition: all .30s ease;
}
.expand-enter, .expand-leave-to {
	height: 0;
	opacity: 0;
}

.badge-warning {
	background-color: #ec9a3d;
	color: white;
}

#new-supplier-name input {
	padding-left: 44px;
}

.week-color-1 {
	// Red
	background-color: #faccd3;
}

.week-color-2 {
	// Blue
	background-color: #b9ddfd
}

.week-color-3 {
	// Green
	background-color: #7ed085;
}

.week-color-0 {
	// Yellow
	background-color: #fcff85;
}

.week-color {
	margin: -0.5rem;
	height: 2.17rem;
}

.week-color div {
	padding: 8px;
	color: rgb(36, 36, 36);
	text-align: center;
}

table, td {
	height: 100%;
}

.datatable-custom-hover tbody tr:hover, 
.datatable-custom-hover tbody tr:hover .custom-sticky-column-name,
.datatable-custom-hover tbody tr:hover .custom-sticky-column-week,
.datatable-custom-hover tbody tr:hover .custom-sticky-column-bid_number {
	background-color: #edf4fb;
}


.datatable-custom-hover tbody tr:hover {
	.week-color-1 {
		// Red
		background-color: #e8c7d3;
	}

	.week-color-2 {
		// Blue
		background-color: #afd5f9;
	}

	.week-color-3 {
		// Green 
		background-color: #7aca8e;
	}

	.week-color-0 {
		// Yellow
		background-color: #eaf48f;
	}
}
	
.datatable-custom-hover tbody tr:hover td:first-child:before {
	content: " ";
	float: left;
	height: 34.5px;
	border-left: 4px solid #5996D6;
	margin: -8px 0 -8px -5px;
	display: block;
}

main {
	overflow-x: visible;
}

.middle-align td {
	vertical-align: middle;
}

.table th, .table td {
	white-space: nowrap;
}

.ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.table-long-text {
	width: 200px;
}

// .estimator-breakdown .ellipsis:hover {
// 	white-space: unset;
// 	text-overflow: unset;
// 	overflow: unset;
// }

.estimator-breakdown .ellipsis {
	width: 200px;
}

.saved-filter:hover {
	cursor: pointer;
}

.estimator-breakdown-list {
	height: 300px;
	overflow-y: auto;
}

.filters-list {
	max-height: 350px;
	overflow-y: auto
}

.table-project-notes {
	width: 220px; 
	height: 25px
}

.table-project-notes i {
	margin-top: 5px;
}

#table-filters {
	width: 60rem;
}

@media only screen and (max-width: 600px) {
	#table-filters {
		width: 100%;
		position: fixed !important;
		top: 0;
		z-index: 100;
	}
}
/*
	Overwrite Boostrap's classes
*/

/* This class is used to add a border bottom of the .modal-header originally this class removes
	the border and the padding bottom. That's why we added this overwrite. */
.modal-header {
	border-width: inherit;
	padding-bottom: 1rem;
}



#full-screen-modal .modal-footer {
	position: fixed;
    width: 100%;
    bottom: 0;
    background-color: white;
    z-index: 3;
}

.form-group {
	margin-bottom: 1rem;
}

/*
	This clases are to prevent move the content under multiselects elements.
*/
.multiselect {
	min-height: 44.39px !important;
}
.multiselect--active .multiselect__tags {
	min-height: 44.39px !important;
}
.multiselect--active .multiselect__tags .multiselect__tags-wrap {
	position: relative !important;
	z-index: 1;
}
.multiselect--active .multiselect__tags .multiselect__input {
	position: absolute !important;
	z-index: 2;
	top: 1px;
	right: 44px;
	bottom: 1px;
	left: 1px;
	width: auto !important;
	min-height: 40px;
}

/*
	CSS for accordion
*/
.accordion-button {
	color: #212121;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: .75rem 1.25rem;
    font-size: .875rem;
	font-weight: bold;
    color: #132144;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s 
	ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}
@media (prefers-reduced-motion:reduce) {
    .accordion-button {
		transition: none;
	}
}
.accordion-button:not(.collapsed) {
    color: #3271e6;
    background-color: #fff;
    box-shadow: inset 0 -.0625rem 0 rgba(231, 234, 243, .7);
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23377dff'%3e%3cpath d='M5.5, 13a.5.5, 0, 0, 1-.5-.5v-1a.5.5, 0, 0, 1, .5-.5h13a.5.5, 0, 0, 1, .5.5v1a.5.5, 0, 0, 1-.5.5Z'/%3e%3c/svg%3e");
    -webkit-transform: rotate(0);
    transform: rotate(0);
}
.accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%212121'%3e%3cpath d='M19, 11.5v1a.5.5, 0, 0, 1-.5.5H13v5.5a.5.5, 0, 0, 1-.5.5h-1a.5.5, 0, 0, 1-.5-.5V13H5.5a.5.5, 0, 0, 1-.5-.5v-1a.5.5, 0, 0, 1, .5-.5H11V5.5a.5.5, 0, 0, 1, .5-.5h1a.5.5, 0, 0, 1, .5.5V11h5.5A.5.5, 0, 0, 1, 19, 11.5Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1rem;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}
@media (prefers-reduced-motion:reduce) {
    .accordion-button:after {
    	transition: none;
	}
}
.accordion-button:hover {
    z-index: 2;
}
.accordion-button:focus {
    z-index: 3;
    border-color: rgba(140, 152, 164, .25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, .25);
}
.accordion-header {
    margin-bottom: 0;
}
.accordion-item {
    background-color: #fff;
    border: .0625rem solid rgba(231, 234, 243, .7);
}
.accordion-item:first-of-type {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: .4375rem;
    border-top-right-radius: .4375rem;
}
.accordion-item:not(:first-of-type) {
    border-top: 0;
}
.accordion-item:last-of-type {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: .4375rem;
    border-bottom-left-radius: .4375rem;
}
.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
.accordion-body {
    padding: .75rem 1.25rem;
}
.accordion-flush .accordion-collapse {
    border-width: 0;
}
.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
    border-top: 0;
}
.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
}
.select-offset select{
	margin-left: -25px
}
.handsontable td.selected {
	background-color: #e6efff;
}
.table thead th {
	border-bottom: 0 !important;
}

.navbar-vertical-aside-mini-mode .overflow-cover {
	left: 92px !important;
}

// sticky;
@supports (position:sticky) {
	.custom-sticky-header {
		position: sticky !important;
		z-index: 6;
	}
	[class^='custom-sticky-column'] {
		position: sticky !important;
		z-index: 1;
		background-color: #fff;
	}
	.b-table-sticky-header>.table.b-table>thead>tr>th{
		position:sticky !important;
		top:0;
		z-index:2;
	}
	.b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
	.b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
	.b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
	.table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
	.table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
	.table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
	[class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
	[class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column,
	[class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column{
		position:sticky !important;
		left:0;
	}
	.b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
	.table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
	[class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column{
		z-index:5;
	}
	.b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
	.b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
	.table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
	.table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
	[class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
	[class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column{
		z-index:2;
	}
	.table.b-table>tbody>tr>.table-b-table-default,
	.table.b-table>tfoot>tr>.table-b-table-default,
	.table.b-table>thead>tr>.table-b-table-default{
		color:#212529;
		background-color:#fff;
	}
	.table.b-table.table-dark>tbody>tr>.bg-b-table-default,
	.table.b-table.table-dark>tfoot>tr>.bg-b-table-default,
	.table.b-table.table-dark>thead>tr>.bg-b-table-default{
		color:#fff;
		background-color:#343a40;
	}
	.table.b-table.table-striped>tbody>tr:nth-of-type(odd)>.table-b-table-default{
		background-image:linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
		background-repeat:no-repeat;
	}
	.table.b-table.table-striped.table-dark>tbody>tr:nth-of-type(odd)>.bg-b-table-default{
		background-image:linear-gradient(hsla(0,0%,100%,.05),hsla(0,0%,100%,.05));
		background-repeat:no-repeat;
	}
	.table.b-table.table-hover>tbody>tr:hover>.table-b-table-default{
		color:#212529;
		background-image:linear-gradient(rgba(0,0,0,.075),rgba(0,0,0,.075));
		background-repeat:no-repeat;
	}
	.table.b-table.table-hover.table-dark>tbody>tr:hover>.bg-b-table-default{
		color:#fff;
		background-image:linear-gradient(hsla(0,0%,100%,.075),hsla(0,0%,100%,.075));
		background-repeat:no-repeat;
	}
	.b-table-sticky-header, .table-responsive {
		margin-bottom: 0 !important;
	}
}

// Reducing min height from 100, since we have a top bar. This helps prevent unnecessary scrollbars. 60px top bar, 2rem padding.
.splitted-content-fluid {
	min-height: calc(100vh - 60px - 2rem);
}

.data-table-infinite th {
	white-space: unset !important;
	vertical-align: top !important;
}
